@import "bootstrap_variables";

body {
	background-color: $body-tertiary-bg;
	min-height: 100%;
	overflow-y: scroll;
}

main {
	$header-height: 56px;
	$footer-height: 50px;
	$main-margin-bottom: map-get($spacers, 5);

	min-height: calc(100vh - #{$header-height} - #{$footer-height} - #{$main-margin-bottom});
}

ul,
ol {
	@media (max-width: map-get($grid-breakpoints, sm)) {
		padding-inline-start: 25px;
	}
}

// hide google recaptcha badge
.grecaptcha-badge {
	visibility: hidden;
}

// ng-select overrides
.ng-select .ng-select-container {
	border-radius: if($enable-rounded, $border-radius, 0px);
}

.ng-dropdown-panel.ng-select-top {
	border-top-right-radius: if($enable-rounded, $border-radius, 0px);
	border-top-left-radius: if($enable-rounded, $border-radius, 0px);
}

.ng-dropdown-panel.ng-select-bottom {
	border-bottom-right-radius: if($enable-rounded, $border-radius, 0px);
	border-bottom-left-radius: if($enable-rounded, $border-radius, 0px);
}

// override Bootstrap's default navbar
.navbar {
	.navbar-nav {
		li.nav-item {
			&.active {
				font-weight: $font-weight-bold;
				border-bottom: solid $primary calc(#{$nav-tabs-border-width} * 2);
			}
		}
	}
}

// additional btn-xs class
.btn-xs {
	padding: ($btn-padding-y-sm * .85) ($btn-padding-x-sm * .85); // Reduce padding. Adjust as needed.
	font-size: $font-size-sm * .85; // Smaller font size than .btn-sm
	// line-height: 1.5; // Standard line-height. Adjust if necessary.
	// border-radius: $border-radius; // Keep consistent border radius with other buttons, or adjust as needed.
}

// global button override to round left-bottom and right-top corners
// .btn {
// 	border-bottom-left-radius: 25px;
// 	border-top-right-radius: 25px;

// 	&.btn-xs {
// 		border-bottom-left-radius: 15px;
// 		border-top-right-radius: 15px;
// 	}

// 	&.btn-sm {
// 		border-bottom-left-radius: 20px;
// 		border-top-right-radius: 20px;
// 	}

// 	&.btn-lg {
// 		border-bottom-left-radius: 30px;
// 		border-top-right-radius: 30px;
// 	}
// }

// .btn {
// 	/* Remove any existing round corners */
// 	border-radius: 0;

// 	/*
//     Clip shape so top-right & bottom-left are "cut" at ~35°.
//     Walking through the polygon (clockwise):
//       1. (0, 0)                  top-left
//       2. (100% - 14px, 0)        move near the top-right
//       3. (100%, 10px)            diagonal cut at top-right
//       4. (100%, 100%)            bottom-right
//       5. (14px, 100%)            move near the bottom-left
//       6. (0, 100% - 10px)        diagonal cut at bottom-left
//       -> closes back to (0, 0)
//   */
// 	clip-path: polygon(0 0,
// 			calc(100% - 14px) 0,
// 			100% 10px,
// 			100% 100%,
// 			14px 100%,
// 			0 calc(100% - 10px));
// }

// /* Example variations for different sizes */
// .btn.btn-xs {
// 	clip-path: polygon(0 0,
// 			calc(100% - 10px) 0,
// 			100% 7px,
// 			100% 100%,
// 			10px 100%,
// 			0 calc(100% - 7px));
// }

// .btn.btn-sm {
// 	clip-path: polygon(0 0,
// 			calc(100% - 12px) 0,
// 			100% 8px,
// 			100% 100%,
// 			12px 100%,
// 			0 calc(100% - 8px));
// }

// .btn.btn-lg {
// 	clip-path: polygon(0 0,
// 			calc(100% - 18px) 0,
// 			100% 12px,
// 			100% 100%,
// 			18px 100%,
// 			0 calc(100% - 12px));
// }

.input-group {
	.btn {
		border-bottom-left-radius: 0;
	}
}

.table-fixed {
	table-layout: fixed;
}

.table {
	thead {
		tr {
			vertical-align: middle;

			th {
				color: $body-tertiary-color;
				font-size: $font-size-base * 0.9;
			}
		}
	}

	&.table-hover {
		tbody {
			tr {
				&.table-row-clickable {
					cursor: pointer !important;
				}
			}
		}
	}
}

// make container be compatible with Bootstrap's .stretched-link class
// most of the <table> elements are not compatible, adding this to <tr> makes them compatible
// https://stackoverflow.com/a/57591579/634906
.stretchable-container {
	transform: rotate(0);
}

// this class allows links behind the stretched link to be clickable
// also use appStopPropagation directive to prevent click event from bubbling up
.inner-stretched-link {
	position: relative;
	z-index: $stretched-link-z-index + 1;
}

.text-wrapped-spaced {
	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: break-all; // make sure long words are broken (like URLs)
}

.text-preserve-whitespace {
	white-space: pre-wrap;
	/* can also use 'pre' or 'pre-line' based on needs */
}

.value-empty {
	font-style: italic !important;
	color: $secondary !important;
}

.c-pointer {
	cursor: pointer !important;
}

.border-dotted {
	border-style: dotted !important;
}

.zoom-075 {
	zoom: 0.75;
}

.text-medium {
	color: $gray-400;
}

.text-decoration-dotted {
	text-decoration: underline dotted $gray-400;
}

.bg-medium {
	background-color: $gray-400;
}

.bg-neutral {
	background-color: $gray-500 !important;
}

.bg-neutral-subtle {
	background-color: tint-color($gray-500, 80%) !important;
}

.border-neutral {
	border-color: $gray-500 !important;
}

// fill extensions for SVG
.fill-neutral {
	fill: $gray-500;
}

.fill-success {
	fill: $success;
}

.fill-warning {
	fill: $warning;
}

.fill-danger {
	fill: $danger;
}

.fill-info {
	fill: $info;
}

.fill-light {
	fill: $light;
}

.fill-dark {
	fill: $dark;
}

.fill-medium {
	fill: $gray-400;
}

.tiny {
	font-size: .70em;
}

// search input icon
input.form-control.form-control-search[type="search"] {
	text-indent: $input-padding-x * 2.1;
	background-position-x: $input-padding-x;
	background-size: calc($input-height * 0.4);

	&.form-control-sm {
		text-indent: $input-padding-x-sm * 2.4;
		background-position-x: $input-padding-x-sm;
		background-size: calc($input-height-sm * 0.4);
	}

	&.form-control-lg {
		text-indent: $input-padding-x-lg * 1.8;
		background-position-x: $input-padding-x-lg;
		background-size: calc($input-height-lg * 0.4);
	}

	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23BDC4CD' d='M504.1 471l-134-134C399.1 301.5 415.1 256.8 415.1 208c0-114.9-93.13-208-208-208S-.0002 93.13-.0002 208S93.12 416 207.1 416c48.79 0 93.55-16.91 129-45.04l134 134C475.7 509.7 481.9 512 488 512s12.28-2.344 16.97-7.031C514.3 495.6 514.3 480.4 504.1 471zM48 208c0-88.22 71.78-160 160-160s160 71.78 160 160s-71.78 160-160 160S48 296.2 48 208z'/%3E%3C/svg%3E");
	background-position-y: 50%;
	background-repeat: no-repeat;
}

p.p-newline {
	margin-top: 0;
	margin-bottom: 0;
	height: 0;
}

.w-sm-auto {
	width: 100%;

	@include media-breakpoint-up(sm) {
		width: auto !important;
	}
}

// creation page filler
// .card {
.card-cover {
	background-color: $gray-300;
	background-image: url("/assets/images/card-cover-bg.png");
	background-repeat: round;
	padding: $grid-gutter-width;
	// height: 100%;
	// min-height: 100%;
	// flex: 1;
}

// }

.field-required {
	label {
		font-weight: 500;
	}

	label:not(.form-check-label)::after {
		content: "*";
		color: $danger;
		margin-left: 5px;
	}
}

// bootstrap switch extensions (additional sizing & rounding fix)
.form-switch {
	display: flex;
	align-items: center;

	.form-check-input {
		// fix for rounded corners to ignore $enable-rounded - switch should always be rounded
		border-radius: valid-radius($form-switch-border-radius);

		padding-left: 2.5rem;
		height: 1.5rem;
		margin-right: $spacer * 0.5;
	}
}

.form-switch-sm {
	.form-check-input {
		padding-left: 2rem;
		height: 1rem;
	}
}
