@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-Black.otf') format('opentype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-BlackItalic.otf') format('opentype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-Bold.otf') format('opentype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-BoldItalic.otf') format('opentype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-ExtraBold.otf') format('opentype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-ExtraBoldItalic.otf') format('opentype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-ExtraLight.otf') format('opentype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-ExtraLightItalic.otf') format('opentype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-Light.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-LightItalic.otf') format('opentype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-Medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-MediumItalic.otf') format('opentype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-Regular.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-RegularItalic.otf') format('opentype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-SemiBold.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-SemiBoldItalic.otf') format('opentype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-Thin.otf') format('opentype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Metropolis';
	src: url('../assets/fonts/Metropolis-ThinItalic.otf') format('opentype');
	font-weight: 100;
	font-style: italic;
}
