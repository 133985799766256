$cut-size: 15px;

.btn:not(.btn-link) {
	clip-path: polygon(0 0,
			calc(100% - $cut-size) 0,
			100% $cut-size,
			100% 100%,
			$cut-size 100%,
			0 calc(100% - $cut-size));

	position: relative;
}

@each $color, $value in $theme-colors {
	.btn-outline-#{$color} {
		&::before {
			content: '';
			position: absolute;
			right: 0;
			top: calc($cut-size - 2px);
			width: $cut-size * 1.35;
			height: var(--#{$prefix}btn-border-width);
			background: $value;
			transform-origin: right;
			transform: rotate(45deg);
		}

		&::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: calc($cut-size - 2px);
			width: $cut-size * 1.35;
			height: var(--#{$prefix}btn-border-width);
			background: $value;
			transform-origin: left;
			transform: rotate(45deg);
		}
	}
}